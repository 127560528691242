import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { API_URL } from '../../../constants';
import { HEADERS } from '../headers';
import { SignInDto } from '../../../dtos/sign-in.dto';
import { SignUpDto } from '../../../dtos/sign-up.dto';
import { MailDto } from '../../../dtos/mail.dto';
import { Success } from '../../../models/success';
import { User } from '../../../models/user';

@Injectable({
	providedIn: 'root'
})
export class ApiAuthService {

	private API_URL: string = API_URL;
	private headers: HttpHeaders = HEADERS;
	private AUTH_PATH: string = 'auth';

	constructor(private http: HttpClient) {}

	public async signIn(signInDto: SignInDto): Promise<HttpResponse<User> | undefined> {
		try {
			return this.http
				.post<User>(`${this.API_URL}/${this.AUTH_PATH}/sign-in`, signInDto, {
					headers: this.headers,
					observe: 'response'
				})
				.toPromise();
		}

		catch(err) {
			return;
		}
	}

	public async signUp(signUpDto: SignUpDto): Promise<HttpResponse<User> | undefined> {
		try {
			return this.http
			.post<User>(`${this.API_URL}/${this.AUTH_PATH}/sign-up`, signUpDto, {
				headers: this.headers,
				observe: 'response'
			})
			.toPromise();
		}

		catch(err) {
			return;
		}
	}

	public async signOut(token: string): Promise<HttpResponse<void> | undefined> {
		try {
			return this.http
				.get<void>(`${this.API_URL}/${this.AUTH_PATH}/sign-out`, {
					headers: this.headers.append('token', token),
					observe: 'response'
				})
				.toPromise();
		}

		catch(err) {
			return;
		}
	}

	public async checkAuth(token: string): Promise<HttpResponse<User> | undefined> {
		try {
			return this.http
				.get<User>(`${this.API_URL}/${this.AUTH_PATH}/check-auth`, {
					headers: this.headers.append('token', token),
					observe: 'response'
				})
				.toPromise();
		}

		catch(err) {
			return;
		}
	}

	public async checkMail(mailDto: MailDto): Promise<HttpResponse<Success> | undefined> {
		try {
			return this.http
				.post<Success>(`${this.API_URL}/${this.AUTH_PATH}/check-mail`, mailDto, {
					headers: this.headers,
					observe: 'response'
				})
				.toPromise();
		}

		catch(err) {
			return;
		}
	}

	public async sendCode(mailDto: MailDto): Promise<HttpResponse<Success> | undefined> {
		try {
			return this.http
				.post<Success>(`${this.API_URL}/${this.AUTH_PATH}/send-code`, mailDto, {
					headers: this.headers,
					observe: 'response'
				})
				.toPromise();
		}

		catch(err) {
			return;
		}
	}

	public async resetPassword(mailDto: MailDto): Promise<HttpResponse<Success> | undefined> {
		try {
			return this.http
				.post<Success>(`${this.API_URL}/${this.AUTH_PATH}/reset-password`, mailDto, {
					headers: this.headers,
					observe: 'response'
				})
				.toPromise();
		}

		catch(err) {
			return;
		}
	}
}
