import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { API_URL } from "../../../constants";
import { HEADERS } from "../headers";
import { Address } from "../../../models/address";
import { ColissimoRDVResponse } from "../../../models/colissimo/colissimo-rdv-response";
import { ColissimoAffranchissement } from "../../../models/colissimo/colissimo-affranchissement";
import { MondialRelayAffranchissement } from "../../../models/mondial-relay/mondial-relay-affranchissement";
import { MondialRelayPoint } from "../../../models/mondial-relay/mondial-relay-point";
import { MondialRelayResponse } from "../../../models/mondial-relay/mondial-relay-response";

@Injectable({
	providedIn: "root",
})
export class ApiDeliveryService {

	private API_URL: string = API_URL;
	private MR_API_URL: string = "https://widget.mondialrelay.com/parcelshop-picker/v4_0/services/parcelshop-picker.svc/";
	private headers: HttpHeaders = HEADERS;
	private DELIVERY_PATH: string = "delivery";

	constructor(private http: HttpClient) {}

	public async getRelayPointFromOrigin(
		token: string,
		address: Address
	): Promise<HttpResponse<ColissimoRDVResponse> | undefined> {
		return this.http
			.post<ColissimoRDVResponse>(`${this.API_URL}/${this.DELIVERY_PATH}/relay-point`, address, {
				headers: this.headers.append("token", token),
				observe: 'response'
			})
			.toPromise();
	}

	public async getMondialRelayPoint(
		postCode: string,
		city: string
	): Promise<HttpResponse<MondialRelayPoint> | undefined> {
		return this.http
			.post<MondialRelayPoint>(`${this.API_URL}/${this.DELIVERY_PATH}/relay-point-mondial-relay`, { postCode, city }, {
				headers: this.headers,
				observe: 'response'
			})
			.toPromise();
	}

	public async generateLabel(
		token: string,
		colissimoAffranchissement: ColissimoAffranchissement
	): Promise<HttpResponse<Blob> | undefined> {
		return this.http
			.post<Blob>(`${this.API_URL}/${this.DELIVERY_PATH}/generate-colissimo`, colissimoAffranchissement, {
				headers: this.headers.append("token", token).set('Accept', 'application/pdf'),
				observe: 'response',
				responseType: 'blob' as 'json'
			})
			.toPromise();
	}

	public async generateMondialRelay(
		token: string,
		colissimoAffranchissement: MondialRelayAffranchissement
	): Promise<HttpResponse<MondialRelayResponse> | undefined> {
		return this.http
			.post<MondialRelayResponse>(`${this.API_URL}/${this.DELIVERY_PATH}/generate-mondial-relay`, colissimoAffranchissement, {
				headers: this.headers.append("token", token),
				observe: 'response'
			})
			.toPromise();
	}
}
