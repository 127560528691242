import { Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';

import { User } from '../../models/user';
import { SignInDto } from '../../dtos/sign-in.dto';
import { SignUpDto } from '../../dtos/sign-up.dto';

import { ApiAuthService } from '../api/api-auth/api-auth.service';
import { isPlatformBrowser } from '@angular/common';

export const BROWSER_STORAGE = new InjectionToken<Storage>('Browser Storage', {
	providedIn: 'root',
	factory: () => localStorage
});

@Injectable({
	providedIn: 'root'
})
export class AccountService {

	private userKey: string = 'user';
	private userTokenKey: string = 'user_token';

	private approvalUser: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(JSON.parse(this.getItem(this.userKey)!));
	private approvalUserToken: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(this.getItem(this.userTokenKey)!);

	public currentUser: Observable<User | null> = this.approvalUser.asObservable();
	public currentUserToken: Observable<string | null> = this.approvalUserToken.asObservable();

	constructor(
		@Inject(PLATFORM_ID) private platformId: Object,
		private _auth: ApiAuthService
	) {}

	private setItem(key: string, value: string): void {
		if(isPlatformBrowser(this.platformId))
			localStorage.setItem(key, value);
	}

	private getItem(key: string): any {
		if(isPlatformBrowser(this.platformId)) {
			const item = localStorage.getItem(key);
			return item || null;
		}

		return null;
	}

	private removeItem(key: string): void {
		if(isPlatformBrowser(this.platformId))
			localStorage.removeItem(key);
	}

	public updateAccount(user: User, token: string): void {
		this.approvalUser.next(user);
		this.approvalUserToken.next(token)

		this.setItem(this.userKey, JSON.stringify(user));
		this.setItem(this.userTokenKey, token);
	}

	public resetAccount(): void {
		this.approvalUser.next(null);
		this.approvalUserToken.next(null);

		this.removeItem(this.userKey);
		this.removeItem(this.userTokenKey);
	}

	public rebaseAccount(): void {
		this.approvalUser.next(<User>JSON.parse(this.getItem(this.userKey)!));
		this.approvalUserToken.next(<string>this.getItem(this.userTokenKey)!);
	}

	public getUser = (): User | null => this.approvalUser.getValue();
	public getUserToken = (): string | null => this.approvalUserToken.getValue();

	public async login(signInDto: SignInDto): Promise<boolean> {
		try {
			let resp: HttpResponse<User> | undefined = await this._auth.signIn(signInDto);

			if(resp) {
				this.updateAccount(resp.body!, resp.headers.get('token')!);
				return true;
			}
		}

		catch (err) {
			return false;
		}

		return false;
	}

	public async register(signUpDto: SignUpDto): Promise<boolean> {
		try {
			let resp: HttpResponse<User> | undefined = await this._auth.signUp(signUpDto);

			if (resp) {
				this.updateAccount(resp.body!, resp.headers.get('token')!);
				return true;
			}
		}

		catch (err) {
			return false;
		}

		return false;
	}

	public async logout(): Promise<void> {
		try {
			await this._auth.signOut(this.getUserToken()!);
			this.resetAccount();
		}

		catch (err) {
			return;
		}
	}

	public async checkAuth(): Promise<boolean> {
		try {
			let resp: HttpResponse<User> | undefined = await this._auth.checkAuth(this.approvalUserToken.getValue()!);

			if(resp) {
				this.updateAccount(resp.body!, resp.headers.get('token')!);
				return true;
			}
		}

		catch (err) {
			this.resetAccount();
			return false;
		}

		return false;
	}
}
